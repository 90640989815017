import { FieldError, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { twMerge } from 'tailwind-merge';

import { useDynamicsForms } from '@/hooks/dynamicsForms';
import { useEnv } from '@/hooks/useEnv';
import { parsePhone } from '@/tools/dynamics';
import { DynamicsDealershipType, DynamicsLeadType } from '@/types/dynamicsForms';
import { faCheckCircle, faClock } from '@fortawesome/free-regular-svg-icons';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormApi } from '@lamesarv-sdk/hooks';
import { navigate } from '@lamesarv-sdk/tools';
import { IFormInput } from '@lamesarv-sdk/types';

import { IForm, IFormProps } from '../Form';
import * as FormClasses from '../Form.classes';
import { validationSchema } from './FormServiceAppointment.schema';

interface IFormServiceAppointment extends IForm {
  store: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  year: string;
  model: string;
  make: string;
  vin: string;
  comments: string;
}

export const FormServiceAppointment = (props: IFormProps) => {
  const { env } = useEnv();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormServiceAppointment>({
    resolver: yupResolver(validationSchema) as unknown as Resolver<IFormServiceAppointment>,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_loadingLegacy, _submittedLegacy, _errorLegacy, sendFormLegacy] = useFormApi();

  const { error, loading, sendForm, submitted } = useDynamicsForms();

  const onSubmit: SubmitHandler<IFormInput> = async (formInput) => {
    const formMappings = [
      {
        FormFieldName: 'leadType',
        DataverseFieldName: 'lmrv_leadtype',
        DataverseFieldValue: [
          { FormValue: 'buyer', DataverseValue: DynamicsLeadType.buyer }, // Buyer
          { FormValue: 'seller', DataverseValue: DynamicsLeadType.seller }, // Seller
          { FormValue: 'service', DataverseValue: DynamicsLeadType.service }, // Service
          { FormValue: 'parts', DataverseValue: DynamicsLeadType.parts }, // Parts
        ],
      },
      {
        FormFieldName: 'store',
        DataverseFieldName: 'lmrv_dealership',
        DataverseFieldValue: [
          { FormValue: 'San Diego', DataverseValue: DynamicsDealershipType.sanDiego },
          { FormValue: 'Davie', DataverseValue: DynamicsDealershipType.davie },
          { FormValue: 'Phoenix', DataverseValue: DynamicsDealershipType.phoenix },
          { FormValue: 'Tucson', DataverseValue: DynamicsDealershipType.tucson },
          { FormValue: 'Albuquerque', DataverseValue: DynamicsDealershipType.albuquerque },
          { FormValue: 'Ft. Myers', DataverseValue: DynamicsDealershipType.ftMyers },
          { FormValue: 'Orlando', DataverseValue: DynamicsDealershipType.orlando },
          { FormValue: 'Port St. Lucie', DataverseValue: DynamicsDealershipType.portStLucie },
        ],
      },
      { FormFieldName: 'firstName', DataverseFieldName: 'firstname' },
      { FormFieldName: 'lastName', DataverseFieldName: 'lastname' },
      { DataverseFieldValue: parsePhone(formInput.phone), DataverseFieldName: 'mobilephone' },
      { FormFieldName: 'email', DataverseFieldName: 'emailaddress1' },
      { FormFieldName: 'year', DataverseFieldName: 'lmrv_vehicleyear' },
      { FormFieldName: 'make', DataverseFieldName: 'lmrv_make' },
      { FormFieldName: 'model', DataverseFieldName: 'lmrv_model' },
      { FormFieldName: 'vin', DataverseFieldName: 'lmrv_vin' },
      { FormFieldName: 'comments', DataverseFieldName: 'lmrv_comments' },
    ];

    if (env.ENABLE_FORMS_LEGACY) {
      sendFormLegacy(formInput);
    }

    await sendForm({
      dynamicsFormId: env.DYNAMICS_FORM_ID_SERVICE,
      mappings: formMappings,
      formId: '#formServiceAppointment',
      callback: () => {
        reset();
        reset({ phone: '' });

        if (props.redirect) {
          navigate(props.redirect);
        }
      },
    });
  };

  return (
    <form
      id="formServiceAppointment"
      className={twMerge('relative p-2', props.className)}
      onSubmit={handleSubmit(onSubmit)}
      data-tag-group={props.tagGroup || 'form'}
      data-tag={props.tagItem ? `form-service-appointment-${props.tagItem}` : 'form-service-appointment'}
    >
      <input type="hidden" {...register('formId')} value="34" />
      <input type="hidden" {...register('formType')} value="service" />
      <input type="hidden" {...register('leadType')} value="service" />
      <input type="hidden" {...register('leadSource')} value="lamesarv" />
      <div className="container">
        {loading && (
          <div
            className={
              'flex items-center w-full bg-gray-100 border-l-4 border-gray-500 text-gray-700 py-2 px-3 mb-4 mx-2 shadow-lg'
            }
            role="alert"
          >
            <FontAwesomeIcon icon={faClock} className="w-8 mr-2" />
            <span>Sending...</span>
          </div>
        )}
        {submitted && !error && (
          <div
            className={
              'flex items-center w-full bg-green-100 border-l-4 border-green-500 text-green-700 py-2 px-3 mb-4 mx-2 shadow-lg'
            }
            role="alert"
          >
            <FontAwesomeIcon icon={faCheckCircle} className="w-8 mr-2" />
            <span>Thank you for requesting more information.</span>
          </div>
        )}
        {submitted && error && (
          <div
            className={
              'flex items-center w-full bg-red-100 border-l-4 border-red-500 text-red-700 py-2 px-3 mb-4 mx-2 shadow-lg'
            }
            role="alert"
          >
            <FontAwesomeIcon icon={faExclamationTriangle} className="w-8 mr-2" />
            <span>An error has occurred, please try again later or contact us using alternative methods.</span>
          </div>
        )}
        <div className="flex flex-col">
          <div className="flex flex-col">
            <h3 className="mb-2 px-2 w-full text-lg text-sage-700 uppercase font-bold">Personal Information</h3>
            <div className="my-2 px-2 w-full">
              <label className="text-sm text-gray-600">Preferred Service Center:</label>
              <select
                {...register('store')}
                className={errors.store ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
                defaultValue=""
              >
                <option value="" disabled>
                  Select an option:
                </option>
                <option value="_disabled" className="py-2" disabled>
                  CALIFORNIA
                </option>
                <option value="San Diego" className="py-2">
                  San Diego
                </option>
                <option value="Davie" className="py-2">
                  Davie
                </option>
                <option value="_disabled" className="py-2" disabled>
                  ARIZONA
                </option>
                <option value="Phoenix" className="py-2">
                  Phoenix
                </option>
                <option value="Tucson" className="py-2">
                  Tucson
                </option>
                <option value="_disabled" className="py-2" disabled>
                  NEW MEXICO
                </option>
                <option value="Albuquerque" className="py-2">
                  Albuquerque
                </option>
                <option value="_disabled" className="py-2" disabled>
                  FLORIDA
                </option>
                <option value="Ft. Myers" className="py-2">
                  Ft. Myers
                </option>
                <option value="Orlando" className="py-2">
                  Orlando
                </option>
                <option value="Port St. Lucie" className="py-2">
                  Port St. Lucie
                </option>
              </select>
              {errors.store && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.store as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full">
              <input
                type="text"
                {...register('firstName')}
                placeholder="First Name*"
                className={errors.firstName ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.firstName && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.firstName as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full">
              <input
                type="text"
                {...register('lastName')}
                placeholder="Last Name*"
                className={errors.lastName ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.lastName && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.lastName as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full">
              <InputMask
                mask="(999) 999-9999"
                {...register('phone')}
                placeholder="Phone*"
                className={errors.phone ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.phone && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.phone as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full">
              <input
                type="email"
                {...register('email')}
                placeholder="Email*"
                className={errors.email ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.email && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.email as unknown as FieldError)?.message}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <h3 className="my-2 px-2 w-full text-lg text-sage-700 uppercase font-bold">Coach/RV Information</h3>
            <div className="my-2 px-2 w-full">
              <input
                type="number"
                min={0}
                step={1}
                {...register('year')}
                placeholder="Year"
                className={errors.year ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.year && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.year as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full">
              <input
                type="text"
                {...register('make')}
                placeholder="Make"
                className={errors.make ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.make && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.make as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full">
              <input
                type="text"
                {...register('model')}
                placeholder="Model"
                className={errors.model ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.model && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.model as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full">
              <input
                type="text"
                {...register('vin')}
                placeholder="VIN"
                className={errors.vin ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.vin && (
                <div className={FormClasses.errorMessageClasses}>{(errors?.vin as unknown as FieldError)?.message}</div>
              )}
            </div>
            <div className="my-2 px-2 w-full">
              <textarea
                {...register('comments')}
                placeholder="Comments"
                className={errors.comments ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.comments && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.comments as unknown as FieldError)?.message}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="p-2">
              <button
                type="submit"
                disabled={loading || !!Object.keys(errors).length}
                className={
                  Object.keys(errors).length ? FormClasses.buttonInactiveClasses : FormClasses.buttonNormalClasses
                }
                data-tag-group={
                  props.tagItem ? `form-service-appointment-${props.tagItem}` : 'form-service-appointment'
                }
                data-tag={
                  props.tagItem ? `form-service-appointment-${props.tagItem}-submit` : 'form-service-appointment-submit'
                }
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
