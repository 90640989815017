import { createContext } from 'react';

export enum GridFavoritesSort {
  RECENTLY_ADDED = 'recentlyAdded',
  PRICE_ASC = 'priceAsc',
  PRICE_DESC = 'priceDesc',
}

export interface GridFavoritesFilters {
  body: string | null;
}

export interface GridFavoritesContextData {
  filters: GridFavoritesFilters;
  sort: GridFavoritesSort;
}

export const GridFavoritesContext = createContext({} as GridFavoritesContextData);
