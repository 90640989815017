export enum FormType {
  career = 'career',
  contactInfo = 'contact-info',
  contactSell = 'contact-sell',
  newsletter = 'newsletter',
  newsletterEmail = 'newsletter-email',
  newsletterFooter = 'newsletter-footer',
  newsletterBlog = 'newsletter-blog',
  serviceAppointment = 'service-appointment',
  parts = 'parts',
  vanUpfit = 'van-upfit',
}
