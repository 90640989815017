'use client';
import { Fragment } from 'react';

import { FormType } from '@/types/forms';
import { IComponentForm, PropsWithTags } from '@lamesarv-sdk/types';

import { FormContactInfo } from './FormContactInfo/FormContactInfo';
import { FormContactSell } from './FormContactSell/FormContactSell';
import { FormParts } from './FormParts/FormParts';
import { FormServiceAppointment } from './FormServiceAppointment/FormServiceAppointment';
import { FormVanUp } from './FormVanUp/FormVanUp';

const getForm = (props: { formType: string; redirect?: string; className?: string }) => {
  switch (props.formType) {
    case FormType.contactInfo:
      return <FormContactInfo {...props} />;
    case FormType.contactSell:
      return <FormContactSell {...props} />;
    case FormType.serviceAppointment:
      return <FormServiceAppointment {...props} />;
    case FormType.parts:
      return <FormParts {...props} />;
    case FormType.vanUpfit:
      return <FormVanUp {...props} />;
    default:
      return <Fragment />;
  }
};

export interface IForm {
  formId: string;
  formType: string;
  leadType: string;
  leadSource?: string;
}

export interface IFormProps extends IComponentForm, PropsWithTags {
  className?: string;
}

export const Form = (props: IFormProps) => {
  return getForm(props);
};
