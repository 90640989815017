'use client';

import { useContext, useEffect } from 'react';

import { InventoryContext } from '@/contexts/InventoryContext';
import { getFavoritesChannel } from '@/tools/broadcast';
import { LaMesaRecVanLogo } from '@lamesarv-sdk/components';
import { useGetInventoryItems } from '@lamesarv-sdk/inventory';
import { getMultipleFilter } from '@lamesarv-sdk/tools';
import {
  IBasicInventory,
  IComponentGridFavorites,
  IMetaData,
  InventoryField,
  ItemsReducerActionType,
} from '@lamesarv-sdk/types';

import { GridFavoritesEmpty } from './GridFavoritesEmpty';
import { GridFavoritesItems, IMinimalInventoryExtended } from './GridFavoritesItems';
import { GridFavoritesLayout } from './GridFavoritesLayout';

interface GridFavoritesProps extends IComponentGridFavorites {
  metadata: IMetaData;
}

export const GridFavorites = (props: GridFavoritesProps) => {
  const { context, dispatch } = useContext(InventoryContext);
  const { fetchItems } = useGetInventoryItems({
    skipFetch: true,
  });

  const updateItems = (itemsBase: IBasicInventory[], itemsFresh: IBasicInventory[]): IMinimalInventoryExtended[] => {
    const itemsNew: IMinimalInventoryExtended[] = [];

    const itemsFreshMap = itemsFresh.reduce(
      (acc, item) => {
        acc[item[InventoryField.id]] = item;
        return acc;
      },
      {} as Record<string, IBasicInventory>,
    );

    for (const item of itemsBase) {
      const itemFound = itemsFreshMap[item[InventoryField.id]];
      if (itemFound) {
        itemsNew.push(itemFound);
      } else {
        itemsNew.push({
          ...item,
          _notAvailable: true,
        });
      }
    }

    return itemsNew;
  };

  const getFreshItems = async (itemsBase: IBasicInventory[]): Promise<IBasicInventory[]> => {
    const itemsFilters = getMultipleFilter(
      InventoryField.stockNumber,
      itemsBase.map((item) => item[InventoryField.id]),
    );
    const itemsConfirmed = await fetchItems({
      filters: itemsFilters,
      currentPage: 1,
      perPage: 250,
    });

    return itemsConfirmed.hits.map((item) => item.document);
  };

  const refreshSelection = async () => {
    const inventoryFavoritesData = localStorage.getItem('inventoryFavorites');

    if (!inventoryFavoritesData) {
      dispatch({
        type: ItemsReducerActionType.updateFavorites,
        payload: [],
      });
      return;
    }

    const inventoryFavorites = JSON.parse(inventoryFavoritesData);
    const inventoryFreshItems = await getFreshItems(inventoryFavorites);
    const inventoryUpdated = updateItems(inventoryFavorites, inventoryFreshItems);

    dispatch({
      type: ItemsReducerActionType.updateFavorites,
      payload: inventoryUpdated,
    });
  };

  useEffect(() => {
    const favoritesChannel = getFavoritesChannel();

    if (!favoritesChannel.isClosed) {
      favoritesChannel.onmessage = async () => {
        refreshSelection();
      };
    }

    refreshSelection();

    return () => {
      if (!favoritesChannel.isClosed) {
        favoritesChannel.close();
      }
    };
  }, []);

  const isLoading = context?.inventoryMetadata === undefined || !context.inventoryFavorites;
  const hasItems = !!context.inventoryFavorites?.length;

  return (
    <GridFavoritesLayout className={props.className} metadata={props.metadata}>
      {/* Items */}
      {hasItems && <GridFavoritesItems gridProps={props} />}

      {/* Items not found */}
      {!isLoading && !hasItems && <GridFavoritesEmpty metadata={props.metadata} />}

      {/* Loading */}
      {isLoading && (
        <div className="flex flex-row justify-center pt-10 animate-pulse">
          <LaMesaRecVanLogo className="h-20" />
        </div>
      )}
    </GridFavoritesLayout>
  );
};
