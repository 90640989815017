import { Fragment, useState } from 'react';

import { mobileFiltersClass } from '@/constants/inventory';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { faFilterList } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Button, ButtonType } from '@lamesarv-sdk/components';
import { IMetaData } from '@lamesarv-sdk/types';

import { InventoryFilters } from './InventoryFilters';

interface IInventoryMobileFiltersProps {
  metadata: IMetaData;
  showSimpleViewButton?: boolean;
}

export const InventoryMobileFilters = ({ metadata, showSimpleViewButton }: IInventoryMobileFiltersProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const openFilters = () => {
    setIsOpen(true);
  };

  const closeFilters = () => {
    setIsOpen(false);
  };

  return (
    <div className="md:hidden">
      <button
        type="button"
        onClick={openFilters}
        className={`w-full h-11 border border-black rounded-md flex gap-2 items-center justify-center ${mobileFiltersClass}`}
      >
        <span>Filter</span>
        <FontAwesomeIcon icon={faFilterList} className="w-5 h-5" />
      </button>

      <Transition show={isOpen} as={Fragment}>
        <Dialog className="relative z-50" onClose={closeFilters}>
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
                <TransitionChild
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <DialogPanel className="pointer-events-auto w-screen">
                    <div className="flex h-full flex-col bg-white shadow-xl" data-testid="mobile-filters-container">
                      <div className="flex items-start justify-between px-4 py-3 border-b">
                        <DialogTitle className="text-lg font-medium uppercase">FILTERS</DialogTitle>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative focus:outline-none"
                            onClick={closeFilters}
                            data-testid="close-filters-btn"
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <FontAwesomeIcon icon={faXmark} className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      {showSimpleViewButton && (
                        <div className="px-4 pt-4">
                          <Button
                            type={ButtonType.primary}
                            onClick={closeFilters}
                            title="VIEW RESULTS"
                            className="w-full justify-center"
                            dataTestId="view-results"
                          />
                        </div>
                      )}
                      <div className="flex-1 flex overflow-hidden">
                        <div className="relative px-4 pb-4 flex flex-1">
                          <InventoryFilters
                            metadata={metadata}
                            hideMobileSearchButton={showSimpleViewButton}
                            isMobile
                          />
                        </div>
                      </div>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
