import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  phone: Yup.string().required('Phone is required'),
  email: Yup.string().email().required('Email is required'),
  year: Yup.string().optional(),
  make: Yup.string().optional(),
  model: Yup.string().optional(),
  vin: Yup.string().optional(),
  store: Yup.string().notOneOf(['_disabled'], 'Preferred Store is required').required('Preferred Store is required'),
  comments: Yup.string().optional(),
});
