import { useSearchParams } from "next/navigation";
import { Fragment, useEffect, useState } from "react";
import { FieldError, Resolver, SubmitHandler, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { twMerge } from "tailwind-merge";

import { useDynamicsForms } from "@/hooks/dynamicsForms";
import { useEnv } from "@/hooks/useEnv";
import { parsePhone } from "@/tools/dynamics";
import { withSuspense } from "@/tools/withSuspense";
import { DynamicsLeadType } from "@/types/dynamicsForms";
import { faCheckCircle, faClock } from "@fortawesome/free-regular-svg-icons";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFormApi } from "@lamesarv-sdk/hooks";
import { useGetInventoryItem } from "@lamesarv-sdk/inventory";
import { navigate, sendAnalyticsEvent } from "@lamesarv-sdk/tools";
import { IFormInput, IInventory, InventoryField } from "@lamesarv-sdk/types";

import { IForm, IFormProps } from "../Form";
import * as FormClasses from "../Form.classes";
import { validationSchema } from "./FormContactInfo.schema";

interface IFormContactInfo extends IForm {
  firstName: string;
  lastName: string;
  phone: string;
  mobile: string;
  email: string;
  zipCode: string;
  comments: string;
  emailType: string;
  stockNumber: string;
}

const emailTypePrefix: { [key: string]: string } = {
  'best-price': 'Best Price for',
  'check-availability': 'Check availability for',
  'more-info': 'More Info for',
};

export interface IFormContactInfoProps extends IFormProps {
  customLeadType?: string;
}

export const FormContactInfo = withSuspense((props: IFormContactInfoProps) => {
  const [stockNumber, setStockNumber] = useState<string | undefined>(undefined);
  const [emailType, setEmailType] = useState<string | undefined>(undefined);
  const [details, setDetails] = useState<string[]>([]);

  const [formInventory, setFormInventory] = useState<IInventory | undefined>(undefined);
  const [formTitle, setFormTitle] = useState<string>('');
  const [formComments, setFormComments] = useState<string>('');

  const searchParams = useSearchParams();
  const { fetchItem } = useGetInventoryItem({ skipFetch: true });
  const { env } = useEnv();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormContactInfo>({ resolver: yupResolver(validationSchema) as unknown as Resolver<IFormContactInfo> });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_loadingLegacy, _submittedLegacy, _errorLegacy, sendFormLegacy] = useFormApi();

  const { loading, submitted, error, sendForm } = useDynamicsForms();

  const onSubmit: SubmitHandler<IFormInput> = async (formInput) => {
    const formMappings = [
      {
        FormFieldName: 'leadType',
        DataverseFieldName: 'lmrv_leadtype',
        DataverseFieldValue: [
          { FormValue: 'buyer', DataverseValue: DynamicsLeadType.buyer }, // Buyer
          { FormValue: 'seller', DataverseValue: DynamicsLeadType.seller }, // Seller
          { FormValue: 'service', DataverseValue: DynamicsLeadType.service }, // Service
          { FormValue: 'parts', DataverseValue: DynamicsLeadType.parts }, // Parts
        ],
      },
      { DataverseFieldName: 'lmrv_stocknumber', DataverseFieldValue: stockNumber ? stockNumber.toLowerCase() : '' },
      { FormFieldName: 'firstName', DataverseFieldName: 'firstname' },
      { FormFieldName: 'lastName', DataverseFieldName: 'lastname' },
      { FormFieldName: 'email', DataverseFieldName: 'emailaddress1' },
      { FormFieldName: 'zipCode', DataverseFieldName: 'address1_postalcode' },
      { FormFieldName: 'comments', DataverseFieldName: 'lmrv_comments' },
      { DataverseFieldValue: parsePhone(formInput.phone), DataverseFieldName: 'telephone2' },
      { DataverseFieldValue: parsePhone(formInput.mobile), DataverseFieldName: 'mobilephone' },
    ];

    if (formComments) {
      formInput.comments = formComments + '\n\n' + formInput.comments;
    }

    if (env.ENABLE_FORMS_LEGACY) {
       sendFormLegacy({
        ...formInput,
        stockNumber: stockNumber ? stockNumber.toLowerCase() : undefined,
        emailType: emailType,
      });
    }

    await sendForm({
      formId: '#formContactInfo',
      mappings: formMappings,
      dynamicsFormId: env.DYNAMICS_FORM_ID_CONTACT_INFO,
      callback: () => {
        reset();
        reset({ phone: '', mobile: '' });
        if (props.redirect) {
          navigate(props.redirect);
        }
      },
    });

    sendAnalyticsEvent(`{${emailType}-submit-success'}`);
  };

  const getInventory = async () => {
    if (!stockNumber) {
      return;
    }

    try {
      const inventoryItem: IInventory = await fetchItem({ inventoryId: stockNumber });
      setFormInventory(inventoryItem);
    } catch (err) {
      console.error('Inventory item not found');
      return;
    }
  };

  const getFormTitle = async () => {
    if (!formInventory) {
      return;
    }

    const titlePrefix = (!!emailType && emailTypePrefix[emailType]) || emailTypePrefix['more-info'];
    setFormTitle(`${titlePrefix} ${formInventory[InventoryField.title]}`);
  };

  const getFormComments = async () => {
    if (!formInventory) {
      return;
    }

    if (details.includes('rebate')) {
      setFormComments(
        `Interested in more information on $${formInventory[InventoryField.rebate].amount.toLocaleString()} rebate\n\n`,
      );
    }
  };

  useEffect(() => {
    getInventory();
  }, [stockNumber]);

  useEffect(() => {
    getFormTitle();
  }, [formInventory, emailType]);

  useEffect(() => {
    getFormComments();
  }, [formInventory, details]);

  useEffect(() => {
    setStockNumber(searchParams.get('stockNumber') || undefined);
    setEmailType(searchParams.get('emailType') || undefined);
    setDetails(
      searchParams
        .get('details')
        ?.trim()
        ?.split(',')
        ?.map((item) => item.trim()) || [],
    );
  }, [searchParams]);

  return (
    <Fragment>
      {formTitle ? (
        <h1 className="text-xl md:text-3xl text-sage-800 font-bold mt-7 mb-5 uppercase ">{formTitle}</h1>
      ) : (
        <h2 className="text-2xl md:text-2xl text-sage-800 font-bold mt-7 uppercase">
          {props.title ?? 'More Information'}
        </h2>
      )}
      <form
        id="formContactInfo"
        className={twMerge('relative p-2', props.className)}
        onSubmit={handleSubmit(onSubmit)}
        data-tag-group={props.tagGroup || 'form'}
        data-tag={props.tagItem ? `form-contact-info-${props.tagItem}` : 'form-contact-info'}
      >
        <input type="hidden" {...register('formId')} value="23" />
        <input type="hidden" {...register('formType')} value="more_info" />
        <input type="hidden" {...register('leadType')} value={props.customLeadType ?? 'buyer'} />
        <input type="hidden" {...register('leadSource')} value="lamesarv" />
        <div className="container">
          <div className="flex flex-col">
            <div className="flex flex-row flex-wrap -mx-2">
              {loading && (
                <div
                  className={
                    'flex items-center w-full bg-gray-100 border-l-4 border-gray-500 text-gray-700 py-2 px-3 mb-4 mx-2 shadow-lg'
                  }
                  role="alert"
                >
                  <FontAwesomeIcon icon={faClock} className="w-8 mr-2" />
                  <span>Sending...</span>
                </div>
              )}
              {submitted && !error && (
                <div
                  className={
                    'flex items-center w-full bg-green-100 border-l-4 border-green-500 text-green-700 py-2 px-3 mb-4 mx-2 shadow-lg'
                  }
                  role="alert"
                >
                  <FontAwesomeIcon icon={faCheckCircle} className="w-8 mr-2" />
                  <span>Thank you for requesting more information.</span>
                </div>
              )}
              {submitted && error && (
                <div
                  className={
                    'flex items-center w-full bg-red-100 border-l-4 border-red-500 text-red-700 py-2 px-3 mb-4 mx-2 shadow-lg'
                  }
                  role="alert"
                >
                  <FontAwesomeIcon icon={faExclamationTriangle} className="w-8 mr-2" />
                  <span>An error has occurred, please try again later or contact us using alternative methods.</span>
                </div>
              )}
              <h3 className="mb-2 px-2 w-full text-lg text-sage-700 uppercase font-bold">Personal Information</h3>
              <div className="my-2 px-2 w-full md:w-1/2">
                <input
                  type="text"
                  {...register('firstName')}
                  placeholder="First Name*"
                  className={errors.firstName ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
                />
                {errors.firstName && (
                  <div className={FormClasses.errorMessageClasses}>
                    {(errors?.firstName as unknown as FieldError)?.message}
                  </div>
                )}
              </div>
              <div className="my-2 px-2 w-full md:w-1/2">
                <input
                  type="text"
                  {...register('lastName')}
                  placeholder="Last Name*"
                  className={errors.lastName ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
                />
                {errors.lastName && (
                  <div className={FormClasses.errorMessageClasses}>
                    {(errors?.lastName as unknown as FieldError)?.message}
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-row flex-wrap -mx-2">
              <h3 className="mt-3 mb-2 px-2 w-full text-lg text-sage-700 uppercase font-bold">Contact Information</h3>
              <div className="my-2 px-2 w-full md:w-1/3">
                <InputMask
                  mask="(999) 999-9999"
                  {...register('phone')}
                  placeholder="Phone"
                  className={errors.phone ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
                />
                {errors.phone && (
                  <div className={FormClasses.errorMessageClasses}>
                    {(errors?.phone as unknown as FieldError)?.message}
                  </div>
                )}
              </div>
              <div className="my-2 px-2 w-full md:w-1/3">
                <InputMask
                  mask="(999) 999-9999"
                  {...register('mobile')}
                  placeholder="Mobile Phone"
                  className={errors.phone ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
                />
                {errors.mobile && (
                  <div className={FormClasses.errorMessageClasses}>
                    {(errors?.mobile as unknown as FieldError)?.message}
                  </div>
                )}
              </div>
              <div className="my-2 px-2 w-full md:w-1/3">
                <input
                  type="email"
                  {...register('email')}
                  placeholder="Email*"
                  className={errors.email ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
                />
                {errors.email && (
                  <div className={FormClasses.errorMessageClasses}>
                    {(errors?.email as unknown as FieldError)?.message}
                  </div>
                )}
              </div>
              <div className="my-2 px-2 w-full md:w-1/3">
                <input
                  type="number"
                  {...register('zipCode')}
                  placeholder="Zip Code*"
                  className={errors.zipCode ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
                />
                {errors.zipCode && (
                  <div className={FormClasses.errorMessageClasses}>
                    {(errors?.zipCode as unknown as FieldError)?.message}
                  </div>
                )}
              </div>
              <div className="my-2 px-2 w-full">
                <textarea
                  {...register('comments')}
                  placeholder="Comments"
                  className={errors.comments ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
                />
                {errors.comments && (
                  <div className={FormClasses.errorMessageClasses}>
                    {(errors?.comments as unknown as FieldError)?.message}
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-row">
              <div className="mt-2">
                <button
                  type="submit"
                  disabled={loading || !!Object.keys(errors).length}
                  className={
                    Object.keys(errors).length ? FormClasses.buttonInactiveClasses : FormClasses.buttonNormalClasses
                  }
                  data-tag-group={props.tagItem ? `form-contact-info-${props.tagItem}` : 'form-contact-info'}
                  data-tag={props.tagItem ? `form-contact-info-${props.tagItem}-submit` : 'form-contact-info-submit'}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
});
