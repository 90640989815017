'use client';
import Link from 'next/link';
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { getFacetCode } from '@lamesarv-sdk/tools';
import { InventoryField, InventoryFieldMap } from '@lamesarv-sdk/types';

type AvailableFilters = InventoryField.body | InventoryField.make | InventoryField.model | InventoryField.inventoryType;

interface QuickInventoryFilterContextData {
  filters: Record<AvailableFilters, string>;
  setFilter: (filter: AvailableFilters, value: string) => void;
}

const QuickInventoryFilterContext = createContext({} as QuickInventoryFilterContextData);

export const QuickInventoryFilterProvider = ({ children }: PropsWithChildren) => {
  const [filters, setFilters] = useState<Record<AvailableFilters, string>>({
    [InventoryField.body]: '',
    [InventoryField.make]: '',
    [InventoryField.model]: '',
    [InventoryField.inventoryType]: '',
  });

  const setFilter = useCallback((filter: AvailableFilters, value: string) => {
    setFilters((prevFilters) => ({ ...prevFilters, [filter]: value }));
  }, []);

  const contextData = useMemo((): QuickInventoryFilterContextData => ({ filters, setFilter }), [filters]);

  return <QuickInventoryFilterContext.Provider value={contextData}>{children}</QuickInventoryFilterContext.Provider>;
};

export interface SingleFilterProps {
  title: string;
  filter: AvailableFilters;
}

export const SingleFilter = ({ title, children, filter }: PropsWithChildren<SingleFilterProps>) => {
  const { filters, setFilter } = useContext(QuickInventoryFilterContext);

  return (
    <div className="border border-black-200 rounded flex flex-col gap-1 md:gap-0.5 px-4 py-2.5 md:p-0 md:pr-3 md:border-0 md:rounded-none md:border-r lg:justify-evenly lg:pl-4 lg:pr-6">
      <span className="text-black-300 text-xs font-medium lg:text-sm">{title}</span>
      <div className="-mr-3">
        <select
          className="focus:outline-none focus:ring-0 focus:border-none border-none p-0 w-full text-sm lg:text-base"
          value={filters[filter]}
          onChange={(e) => setFilter(filter, e.target.value)}
        >
          {children}
        </select>
      </div>
    </div>
  );
};

interface ModelFilterProps {
  models: Record<string, string[]>;
}

export const ModelFilter = ({ models }: ModelFilterProps) => {
  const { filters, setFilter } = useContext(QuickInventoryFilterContext);

  useEffect(() => {
    if (!filters[InventoryField.make]) return;

    setFilter(InventoryField.model, '');
  }, [filters[InventoryField.make]]);

  const allModels = useMemo(
    () => Object.values(models).reduce((acc, models) => [...acc, ...models], [] as string[]),
    [models],
  );

  const filteredModels = (filters[InventoryField.make] ? models[filters[InventoryField.make]] : allModels) || [];
  filteredModels.sort((a, b) => a.localeCompare(b));

  return (
    <SingleFilter title="Model" filter={InventoryField.model}>
      <option value="">All Models</option>
      {filteredModels.map((model, idx) => (
        <option key={model + idx} value={model}>
          {model}
        </option>
      ))}
    </SingleFilter>
  );
};

export const SearchButton = () => {
  const { filters } = useContext(QuickInventoryFilterContext);

  const queryFilters: string[] = [];
  for (const [key, value] of Object.entries(filters)) {
    if (!value) continue;

    if (value.startsWith('filters:')) {
      queryFilters.push(value.replace('filters:', ''));
      continue;
    }

    queryFilters.push(`${InventoryFieldMap.get(key)}=${getFacetCode(value)}`);
  }

  const query = queryFilters.length > 0 ? `?${queryFilters.join('&')}` : '';

  return (
    <Link
      href={`/search${query}`}
      type="button"
      className="rounded md:rounded-sm bg-blue-500 h-[50px] flex items-center justify-center font-medium lg:font-bold text-lg md:text-sm lg:text-lg text-white hover:bg-blue-700 md:h-auto lg:h-[60px] col-span-2 md:col-span-1"
    >
      Search all RVs
    </Link>
  );
};
