import { FieldError, Resolver, SubmitHandler, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { twMerge } from "tailwind-merge";

import { useDynamicsForms } from "@/hooks/dynamicsForms";
import { useEnv } from "@/hooks/useEnv";
import { parsePhone } from "@/tools/dynamics";
import { DynamicsLeadType } from "@/types/dynamicsForms";
import { faCheckCircle, faClock } from "@fortawesome/free-regular-svg-icons";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFormApi } from "@lamesarv-sdk/hooks";
import { navigate } from "@lamesarv-sdk/tools";
import { IFormInput } from "@lamesarv-sdk/types";

import { IForm, IFormProps } from "../Form";
import * as FormClasses from "../Form.classes";
import { validationSchema } from "./FormContactSell.schema";

interface IFormContactSell extends IForm {
  make: string;
  model: string;
  year: string;
  miles: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  city: string;
  state: string;
  zipCode: string;
}

export const FormContactSell = (props: IFormProps) => {
  const { env } = useEnv();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormContactSell>({ resolver: yupResolver(validationSchema) as unknown as Resolver<IFormContactSell> });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_loadingLegacy, _submittedLegacy, _errorLegacy, sendFormLegacy] = useFormApi();

  const { loading, submitted, error, sendForm } = useDynamicsForms();

  const onSubmit: SubmitHandler<IFormInput> = async (formInput) => {
    const formMappings = [
      {
        FormFieldName: 'leadType',
        DataverseFieldName: 'lmrv_leadtype',
        DataverseFieldValue: [
          { FormValue: 'buyer', DataverseValue: DynamicsLeadType.buyer }, // Buyer
          { FormValue: 'seller', DataverseValue: DynamicsLeadType.seller }, // Seller
          { FormValue: 'service', DataverseValue: DynamicsLeadType.service }, // Service
          { FormValue: 'parts', DataverseValue: DynamicsLeadType.parts }, // Parts
        ],
      },
      {
        FormFieldName: 'leadSource',
        DataverseFieldName: 'leadsourcecode',
        DataverseFieldValue: [
          { FormValue: 'lamesarv', DataverseValue: '3' }, // lamesarv.com
        ],
      },
      { FormFieldName: 'make', DataverseFieldName: 'lmrv_make' },
      { FormFieldName: 'year', DataverseFieldName: 'lmrv_vehicleyear' },
      { FormFieldName: 'model', DataverseFieldName: 'lmrv_model' },
      { FormFieldName: 'miles', DataverseFieldName: 'lmrv_miles' },
      { FormFieldName: 'firstName', DataverseFieldName: 'firstname' },
      { FormFieldName: 'email', DataverseFieldName: 'emailaddress1' },
      { FormFieldName: 'city', DataverseFieldName: 'address1_city' },
      { FormFieldName: 'zipCode', DataverseFieldName: 'address1_postalcode' },
      { FormFieldName: 'lastName', DataverseFieldName: 'lastname' },
      { FormFieldName: 'state', DataverseFieldName: 'address1_stateorprovince' },
      { DataverseFieldValue: parsePhone(formInput.phone), DataverseFieldName: 'mobilephone' },
    ];

    if (env.ENABLE_FORMS_LEGACY) {
       sendFormLegacy(formInput);
    }

    await sendForm({
      formId: '#formContactSell',
      mappings: formMappings,
      dynamicsFormId: env.DYNAMICS_FORM_ID_CONTACT_SELL,
      callback: () => {
        reset();
        reset({ phone: '' });
        if (props.redirect) {
          navigate(props.redirect);
        }
      },
    });
  };

  return (
    <form
      id="formContactSell"
      className={twMerge('relative p-2', props.className)}
      onSubmit={handleSubmit(onSubmit)}
      data-tag-group={props.tagGroup || 'form'}
      data-tag={props.tagItem ? `form-contact-sell-${props.tagItem}` : 'form-contact-sell'}
    >
      <input type="hidden" {...register('formId')} value="25" />
      <input type="hidden" {...register('formType')} value="seller" />
      <input type="hidden" {...register('leadType')} value="buyer" />
      <input type="hidden" {...register('leadSource')} value="lamesarv" />
      <div className="container">
        <div className="flex flex-col">
          <div className="flex flex-row flex-wrap -mx-2">
            {loading && (
              <div
                className={
                  'flex items-center w-full bg-gray-100 border-l-4 border-gray-500 text-gray-700 py-2 px-3 mb-4 mx-2 shadow-lg'
                }
                role="alert"
              >
                <FontAwesomeIcon icon={faClock} className="w-8 mr-2" />
                <span>Sending...</span>
              </div>
            )}
            {submitted && !error && (
              <div
                className={
                  'flex items-center w-full bg-green-100 border-l-4 border-green-500 text-green-700 py-2 px-3 mb-4 mx-2 shadow-lg'
                }
                role="alert"
              >
                <FontAwesomeIcon icon={faCheckCircle} className="w-8 mr-2" />
                <span>
                  Thank you for requesting more information, a member of our Buying Team will reach out to you shortly.
                </span>
              </div>
            )}
            {error && (
              <div
                className={
                  'flex items-center w-full bg-red-100 border-l-4 border-red-500 text-red-700 py-2 px-3 mb-4 mx-2 shadow-lg'
                }
                role="alert"
              >
                <FontAwesomeIcon icon={faExclamationTriangle} className="w-8 mr-2" />
                <span>An error has occurred, please try again later or contact us using alternative methods.</span>
              </div>
            )}
            <h3 className="mb-2 px-2 w-full text-lg text-sage-700 uppercase font-bold">Tell Us About Your RV</h3>
            <div className="my-2 px-2 w-full md:w-2/4">
              <input
                type="text"
                {...register('make')}
                placeholder="Make*"
                className={errors.make ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.make && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.make as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full md:w-2/4">
              <input
                type="text"
                {...register('model')}
                placeholder="Model*"
                className={errors.model ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.model && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.model as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full md:w-2/4">
              <input
                type="number"
                min={0}
                step={1}
                {...register('year')}
                placeholder="Year*"
                className={errors.year ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.year && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.year as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full md:w-2/4">
              <input
                type="number"
                min={0}
                step={1}
                {...register('miles')}
                placeholder="Miles*"
                className={errors.miles ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.miles && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.miles as unknown as FieldError)?.message}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-row flex-wrap -mx-2">
            <h3 className="my-2 px-2 w-full text-lg text-sage-700 uppercase font-bold">Tell Us How We Can Reach You</h3>
            <div className="my-2 px-2 w-full md:w-1/2">
              <input
                type="text"
                {...register('firstName')}
                placeholder="First Name*"
                className={errors.firstName ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.firstName && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.firstName as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full md:w-1/2">
              <input
                type="text"
                {...register('lastName')}
                placeholder="Last Name*"
                className={errors.lastName ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.lastName && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.lastName as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full md:w-1/2">
              <input
                type="email"
                {...register('email')}
                placeholder="Email*"
                className={errors.email ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.email && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.email as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full md:w-1/2">
              <InputMask
                mask="(999) 999-9999"
                {...register('phone')}
                placeholder="Phone*"
                className={errors.phone ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.phone && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.phone as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full">
              <input
                type="text"
                {...register('city')}
                placeholder="City*"
                className={errors.city ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.city && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.city as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full md:w-1/2">
              <select
                {...register('state')}
                className={errors.state ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              >
                <option value="">State*</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
              {errors.state && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.state as unknown as FieldError)?.message}
                </div>
              )}
            </div>
            <div className="my-2 px-2 w-full md:w-1/2">
              <input
                type="text"
                {...register('zipCode')}
                placeholder="Zip*"
                className={errors.zipCode ? FormClasses.inputErrorClasses : FormClasses.inputNormalClasses}
              />
              {errors.zipCode && (
                <div className={FormClasses.errorMessageClasses}>
                  {(errors?.zipCode as unknown as FieldError)?.message}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-row">
            <div className="mt-2">
              <button
                type="submit"
                disabled={loading || !!Object.keys(errors).length}
                className={
                  Object.keys(errors).length ? FormClasses.buttonInactiveClasses : FormClasses.buttonNormalClasses
                }
                data-tag-group={props.tagItem ? `form-contact-sell-${props.tagItem}` : 'form-contact-sell'}
                data-tag={props.tagItem ? `form-contact-sell-${props.tagItem}-submit` : 'form-contact-sell-submit'}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
