'use client';
import { useEffect } from 'react';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import * as yup from 'yup';

import { useDynamicsForms } from '@/hooks/dynamicsForms';
import { useEnv } from '@/hooks/useEnv';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormApi } from '@lamesarv-sdk/hooks';
import { IFormInput } from '@lamesarv-sdk/types';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
}

const schema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email('Email is not valid').required('Email is required'),
});

export const StayUpdated = () => {
  const { env } = useEnv();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema) as unknown as Resolver<FormValues>,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_loadingLegacy, _submittedLegacy, _errorLegacy, sendFormLegacy] = useFormApi();

  const { loading, submitted, error, sendForm } = useDynamicsForms();

  const onSubmit: SubmitHandler<IFormInput> = async (formInput) => {
    const formMappings = [
      {
        FormFieldName: 'firstName',
        DataverseFieldName: 'firstname',
      },
      {
        FormFieldName: 'lastName',
        DataverseFieldName: 'lastname',
      },
      {
        FormFieldName: 'email',
        DataverseFieldName: 'emailaddress1',
      },
    ];

    if (env.ENABLE_FORMS_LEGACY) {
      sendFormLegacy({
        ...formInput,
        formId: '28',
        formType: 'email_newsletter',
      });
    }

    await sendForm({
      formId: '#formStayUpdated',
      mappings: formMappings,
      dynamicsFormId: env.DYNAMICS_FORM_ID_NEWSLETTER,
      callback: () => {
        reset();
      },
    });
  };

  const wasSuccessful = submitted && !error;

  useEffect(() => {
    if (wasSuccessful) {
      reset();
    }
  }, [wasSuccessful]);

  return (
    <div className="px-6 my-16 font-helvetica text-black-500 md:px-10 lg:my-24 lg:container lg:mx-auto scroll-mt-24">
      <div className="border border-black-300 rounded-xl py-16 md:py-12 px-11 md:px-32 lg:pt-9 lg:pb-20">
        <div className="flex flex-col lg:max-w-[484px] lg:mx-auto">
          <h3 className="text-2xl md:text-[1.75rem] leading-normal font-bold text-center lg:text-[2.25rem]">
            Stay Updated
          </h3>
          <p className="mt-1.5 md:mt-1 text-xs md:text-sm text-center lg:leading-8">
            Stay informed on new arrivals and best deals!
          </p>
          <form
            id="formStayUpdated"
            className={twMerge('mt-10 md:mt-5 flex flex-col gap-5', loading && 'opacity-50')}
            onSubmit={handleSubmit(onSubmit)}
            data-tag-group="home"
            data-tag="form-stay-updated"
          >
            <div className="flex flex-col gap-5 md:flex-row md:items-start md:gap-6">
              <div className="flex flex-col gap-2 md:flex-1">
                <input
                  type="text"
                  placeholder="First Name"
                  {...register('firstName')}
                  disabled={loading}
                  className={twMerge(
                    'border-0 border-b border-black-200 focus:outline-none focus:ring-0 py-2 px-0 text-sm md:text-base md:py-3 placeholder-black-300',
                    errors.firstName && 'border-red-500',
                  )}
                />

                {errors.firstName && (
                  <span className="text-xs md:text-sm text-red-500">{errors.firstName.message}</span>
                )}
              </div>

              <div className="flex flex-col gap-2 md:flex-1">
                <input
                  type="text"
                  placeholder="Last Name"
                  {...register('lastName')}
                  disabled={loading}
                  className={twMerge(
                    'border-0 border-b border-black-200 focus:outline-none focus:ring-0 py-2 px-0 text-sm md:text-base md:py-3 placeholder-black-300',
                    errors.lastName && 'border-red-500',
                  )}
                />
                {errors.lastName && <span className="text-xs md:text-sm text-red-500">{errors.lastName.message}</span>}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div
                className={twMerge(
                  'flex items-center border-b border-black-300 gap-2 py-2 md:py-3',
                  errors.email && 'border-red-500',
                )}
              >
                <FontAwesomeIcon icon={faEnvelope} className="size-4 shrink-0 md:size-5" />
                <input
                  type="email"
                  placeholder="Email address"
                  {...register('email')}
                  disabled={loading}
                  className="border-none focus:outline-none focus:ring-0 p-0 text-sm md:text-base placeholder-black-300 flex-1"
                />
                <button
                  type="submit"
                  disabled={loading}
                  className="text-sm md:text-base font-bold disabled:opacity-50"
                  data-tag-group="form-stay-updated"
                  data-tag="form-stay-updated-submit"
                >
                  Signup
                </button>
              </div>
              {errors.email && <span className="text-xs md:text-sm text-red-500">{errors.email.message}</span>}
            </div>
          </form>
          {wasSuccessful && (
            <span className="text-xs md:text-sm mt-10 md:mt-5 text-center">Thank you for Signing Up!</span>
          )}
        </div>
      </div>
    </div>
  );
};
