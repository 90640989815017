'use client';

import { PropsWithChildren } from 'react';
import Slider from 'react-slick';
import { twMerge } from 'tailwind-merge';

import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CustomArrowProps {
  onClick?: () => void;
  className?: string;
  isRight?: boolean;
}

const CustomArrow = ({ className, onClick, isRight }: CustomArrowProps) => (
  <button
    type="button"
    onClick={onClick}
    className={twMerge(
      'size-8 md:size-12 bg-white rounded-full shadow-[0px_1px_10px_0px_#0000001A] absolute top-1/2 -translate-y-1/2 flex items-center outline-none justify-center cursor-pointer hover:bg-gray-100 z-10',
      isRight ? 'right-0' : 'left-0',
      className,
    )}
    data-testid={isRight ? 'right-arrow' : 'left-arrow'}
  >
    <FontAwesomeIcon className="size-3.5 md:size-5" icon={isRight ? faArrowRight : faArrowLeft} />
  </button>
);

export const ManufacturerLogosSlider = ({ children }: PropsWithChildren) => (
  <Slider
    dots={false}
    arrows
    infinite
    slidesToScroll={6}
    slidesToShow={6}
    className="border-y border-black-200 py-8 px-10 md:px-14 lg:px-16"
    nextArrow={<CustomArrow isRight />}
    prevArrow={<CustomArrow />}
    responsive={[
      {
        breakpoint: 1024,
        settings: {
          slidesToScroll: 3,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToScroll: 2,
          slidesToShow: 2,
        },
      },
    ]}
  >
    {children}
  </Slider>
);
