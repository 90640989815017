'use client';
import Link from 'next/link';
import Slider from 'react-slick';
import { twMerge } from 'tailwind-merge';

import { IComponentHomeHero } from '@/types/cms';
import { faArrowLeft, faArrowRight, faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CustomArrowProps {
  isRight?: boolean;
  onClick?: () => void;
}

const CustomArrow = ({ isRight, onClick }: CustomArrowProps) => (
  <button
    type="button"
    onClick={onClick}
    className={twMerge(
      'absolute top-1/2 -translate-y-1/2 flex items-center justify-center cursor-pointer z-10 outline-none size-9 md:size-7 lg:size-10 md:rounded-full text-white max-md:hover:text-gray-100 md:text-black-500 md:bg-white md:hover:bg-gray-100',
      isRight ? 'right-0 md:right-4 lg:right-8' : 'left-0 md:left-4 lg:left-8',
    )}
  >
    <FontAwesomeIcon
      className="size-6 drop-shadow-[0px_0px_3px_0px_#00000080] md:hidden"
      icon={isRight ? faChevronRight : faChevronLeft}
    />
    <FontAwesomeIcon className="size-3 lg:size-4 max-md:hidden" icon={isRight ? faArrowRight : faArrowLeft} />
  </button>
);

const CustomPaging = () => (
  <div className="size-3 md:size-2.5 lg:size-3.5 rounded-full border border-white p-0.5 cursor-pointer hover:border-gray-100">
    <figure className="size-full rounded-full" />
  </div>
);

export const HomeHero = ({ images, speed: rawSpeed }: IComponentHomeHero) => {
  const speed = rawSpeed ? Number(rawSpeed) * 1000 : undefined;

  return (
    <div className="px-6 md:px-10 my-8 md:my-10 lg:my-16 lg:container lg:mx-auto">
      <Slider
        slidesToScroll={1}
        slidesToShow={1}
        infinite={images.length > 1}
        arrows
        nextArrow={<CustomArrow isRight />}
        prevArrow={<CustomArrow />}
        dots
        dotsClass="absolute bottom-5 md:bottom-3 lg:bottom-5 right-1/2 !flex gap-1.5 translate-x-1/2 [&>li.slick-active_figure]:!bg-white z-10"
        customPaging={CustomPaging}
        adaptiveHeight
        autoplay
        autoplaySpeed={isNaN(speed) || !speed ? 3000 : speed}
      >
        {images.map((image, index) => {
          const Container = image.overlay_link ? Link : 'div';

          return (
            <Container
              href={image.overlay_link || undefined}
              key={image.alt + index}
              className="relative rounded-xl md:rounded lg:rounded-[0.625rem] overflow-hidden"
            >
              <img src={image.mobile_url} alt={image.alt} className="size-full object-cover min-[430px]:hidden" />
              <img
                src={image.large_mobile_url}
                alt={image.alt}
                className="size-full object-cover max-[429px]:hidden md:hidden"
              />
              <img src={image.tablet_url} alt={image.alt} className="size-full object-cover max-md:hidden lg:hidden" />
              <img
                src={image.small_desktop_url}
                alt={image.alt}
                className="size-full object-cover max-lg:hidden min-[1440px]:hidden"
              />
              <img
                src={image.medium_desktop_url}
                alt={image.alt}
                className="size-full object-cover max-[1439px]:hidden min-[1920px]:hidden"
              />
              <img
                src={image.large_desktop_url}
                alt={image.alt}
                className="size-full object-cover max-[1919px]:hidden"
              />

              {(image.overlay_text || image.overlay_subtext) && (
                <div
                  className={twMerge(
                    'absolute bottom-0 left-0 w-full h-[70%] bg-gradient-to-t from-black to-transparent md:bg-none px-5 pb-14 flex flex-col justify-end gap-3 text-white font-helvetica',
                    'md:h-full md:justify-center md:items-center md:max-w-lg md:left-1/2 md:-translate-x-1/2 md:pb-0',
                    'lg:max-w-xl',
                  )}
                >
                  {image.overlay_text && (
                    <h2 className="text-2xl font-bold md:text-center md:text-[2rem] md:max-w-sm lg:text-5xl lg:max-w-xl">
                      {image.overlay_text}
                    </h2>
                  )}
                  {image.overlay_subtext && (
                    <p className="text-sm md:text-base md:text-center">{image.overlay_subtext}</p>
                  )}

                  {image.overlay_link_text && (
                    <span className="font-medium border-b border-white mt-2 lg:mt-3 hover:border-gray-100 hover:text-gray-100 max-md:hidden">
                      {image.overlay_link_text}
                    </span>
                  )}
                </div>
              )}
            </Container>
          );
        })}
      </Slider>
    </div>
  );
};
