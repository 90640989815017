'use client';
import { createContext, PropsWithChildren, useMemo } from 'react';

interface FiltersToHideContextData {
  filtersToHide: Set<string>;
}

export const FiltersToHideContext = createContext({} as FiltersToHideContextData);

interface FiltersToHideProviderProps {
  filtersToHide?: Set<string>;
}

export const FiltersToHideProvider = ({ children, filtersToHide }: PropsWithChildren<FiltersToHideProviderProps>) => {
  const data = useMemo(
    (): FiltersToHideContextData => ({
      filtersToHide: filtersToHide || new Set<string>(),
    }),
    [filtersToHide],
  );

  return <FiltersToHideContext.Provider value={data}>{children}</FiltersToHideContext.Provider>;
};
