'use client';
import Link from 'next/link';
import { PropsWithChildren, useContext, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { InventoryContext } from '@/contexts/InventoryContext';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCustomSearchParams } from '@lamesarv-sdk/hooks';
import { IMetaData, InventoryField, InventoryFieldMap } from '@lamesarv-sdk/types';

import { InventorySlider } from '../Inventory/InventorySlider';
import {
  GridFavoritesContext,
  GridFavoritesContextData,
  GridFavoritesFilters,
  GridFavoritesSort,
} from './GridFavoritesContext';

interface SecondarySectionProps {
  title: string;
  description: string;
  extraHeader?: JSX.Element;
}

export const SecondarySection = ({
  description,
  title,
  children,
  extraHeader,
}: PropsWithChildren<SecondarySectionProps>) => (
  <section className="mt-12 md:mt-20">
    <div className="flex justify-between items-center md:items-end mb-9">
      <div className="flex flex-col">
        <h2 className="font-bold text-lg md:text-[1.75rem]">{title}</h2>
        <p className="mt-3.5 text-[#3F585E] max-md:hidden">{description}</p>
      </div>
      {extraHeader}
    </div>
    {children}
  </section>
);

const ViewAllLink = ({ href }: { href: string }) => (
  <Link prefetch href={href} className="flex items-center gap-1 md:gap-2 mr-2 md:mr-3.5">
    <span className="font-bold text-xs md:text-base">View All</span>
    <FontAwesomeIcon icon={faChevronRight} className="size-2.5 md:size-4" />
  </Link>
);

interface GridFavoritesLayoutProps {
  className?: string;
  metadata: IMetaData;
}

export const GridFavoritesLayout = ({ children, className, metadata }: PropsWithChildren<GridFavoritesLayoutProps>) => {
  const { context } = useContext(InventoryContext);
  const hasItems = !!context.inventoryFavorites?.length;

  const [filters, setFilters] = useState<GridFavoritesFilters>({ body: null });
  const [sort, setSort] = useState<GridFavoritesSort>(GridFavoritesSort.RECENTLY_ADDED);
  const { setSearchParam } = useCustomSearchParams();

  const contextData = useMemo(
    (): GridFavoritesContextData => ({
      filters,
      sort,
    }),
    [filters, sort],
  );

  return (
    <GridFavoritesContext.Provider value={contextData}>
      <div className={twMerge('relative mt-14 md:mt-20 mb-[7.5rem] font-inter text-[#0F0603]', className)}>
        <div className="mb-6 flex items-end justify-between">
          <h1 className="font-bold text-xl md:text-[1.75rem]">My Favorites</h1>
          {hasItems && (
            <div className="flex gap-1.5 md:gap-4">
              <select
                className="border border-[#75787B] rounded px-1.5 md:py-2 md:px-4 pr-6 md:pr-9 text-xs md:text-base"
                value={filters.body || ''}
                onChange={(e) => {
                  setSearchParam('page', '1');
                  setFilters({ ...filters, body: e.target.value || null });
                }}
              >
                <option value="">All Types</option>
                {metadata.pagesBodyData.map((bodyItem) => (
                  <option key={bodyItem.title} value={bodyItem.title}>
                    {bodyItem.title}
                  </option>
                ))}
              </select>
              <select
                className="border border-[#75787B] rounded py-0.5 px-1.5 md:py-2 md:px-4 pr-6 md:pr-9 text-xs md:text-base"
                value={sort}
                onChange={(e) => {
                  setSearchParam('page', '1');
                  setSort(e.target.value as GridFavoritesSort);
                }}
              >
                <option value={GridFavoritesSort.RECENTLY_ADDED}>Sort By: Recent</option>
                <option value={GridFavoritesSort.PRICE_ASC}>Price Asc</option>
                <option value={GridFavoritesSort.PRICE_DESC}>Price Desc</option>
              </select>
            </div>
          )}
        </div>
        {children}
        <SecondarySection
          title="Discover Popular Choices"
          description="Looking for inspiration? These RVs are loved by adventurers like you!"
          extraHeader={<ViewAllLink href="/search" />}
        >
          <InventorySlider filters="" />
        </SecondarySection>
        <SecondarySection
          title="Daily Deals: Drive the Savings!"
          description="Don’t miss your chance to snag incredible savings on RVs built for your next journey!"
          extraHeader={<ViewAllLink href={`/search?${InventoryFieldMap.get(InventoryField.isDailyDeal)}=true`} />}
        >
          <InventorySlider filters={`${InventoryField.isDailyDeal}:=true`} />
        </SecondarySection>
      </div>
    </GridFavoritesContext.Provider>
  );
};
