'use client';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import Slider from 'react-slick';
import { twMerge } from 'tailwind-merge';

import { IComponentPromotionUnits } from '@/types/cms';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IInventory, InventoryField } from '@lamesarv-sdk/types';

import { InventoryItem } from '../Inventory/InventoryItem';

const colorClassesRecord: Record<string, { border: string; bg: string; buttonBorder: string; bgHover: string }> = {
  red: {
    border: 'border-red-400',
    bg: 'bg-red-600',
    buttonBorder: 'border-red-300',
    bgHover: 'hover:bg-red-700',
  },
  green: {
    border: 'border-green-400',
    bg: 'bg-green-600',
    buttonBorder: 'border-green-300',
    bgHover: 'hover:bg-green-700',
  },
  blue: {
    border: 'border-blue-400',
    bg: 'bg-blue-600',
    buttonBorder: 'border-blue-300',
    bgHover: 'hover:bg-blue-700',
  },
  yellow: {
    border: 'border-yellow-400',
    bg: 'bg-yellow-600',
    buttonBorder: 'border-yellow-300',
    bgHover: 'hover:bg-yellow-700',
  },
  black: {
    border: 'border-black-400',
    bg: 'bg-black-500',
    buttonBorder: 'border-black-300',
    bgHover: 'hover:bg-black-600',
  },
};

interface CustomArrowProps {
  onClick?: () => void;
  isRight?: boolean;
  currentSlide?: number;
  link: string;
}

const CustomArrow = ({ onClick, isRight, currentSlide = 0, link }: CustomArrowProps) => {
  const router = useRouter();

  return (
    <button
      type="button"
      onClick={isRight && !onClick ? () => router.push(link) : onClick}
      className={twMerge(
        'rounded-full bg-white p-3.5 shadow-[0px_2px_8px_0px_#00000033] outline-none absolute top-1/2 -translate-y-1/2 flex justify-center items-center z-10 hover:bg-gray-100',
        isRight ? 'right-4' : 'left-4',
        currentSlide === 0 && !isRight && 'hidden',
      )}
    >
      <FontAwesomeIcon className="size-4" icon={isRight ? faChevronRight : faChevronLeft} />
    </button>
  );
};

interface WrappedPromotionUnitsProps extends IComponentPromotionUnits {
  items: IInventory[];
}

export const WrappedPromotionUnits = ({
  background_image_url,
  color = 'red',
  type,
  title,
  description,
  cta_text,
  link,
  items,
}: WrappedPromotionUnitsProps) => {
  const colorClasses = colorClassesRecord[color];
  const router = useRouter();

  return (
    <div className="lg:px-10 lg:container lg:mx-auto font-helvetica">
      <Slider
        variableWidth
        slidesToScroll={3}
        slidesToShow={3}
        responsive={[
          {
            breakpoint: 1280,
            settings: {
              slidesToScroll: 2,
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToScroll: 1,
              slidesToShow: 1,
            },
          },
        ]}
        infinite={false}
        dots={false}
        arrows
        nextArrow={<CustomArrow isRight link={link} />}
        prevArrow={<CustomArrow link={link} />}
        className="[&_.slick-track]:!m-0 my-16 md:my-12"
        onEdge={(edge) => {
          if (edge === 'left') {
            router.push(link);
          }
        }}
      >
        <div className="w-80 lg:w-96 pl-6 md:pl-10 lg:pl-0 md:-mr-5 lg:mr-5 h-full py-4">
          <Link
            href={link || '#'}
            className={twMerge(
              'relative rounded-[0.625rem] overflow-hidden border pl-8 pr-10 md:px-3 lg:px-9 flex items-center w-80 lg:w-96 h-full',
              colorClasses.border,
              colorClasses.bg,
            )}
          >
            <img src={background_image_url} className="size-full object-cover absolute top-0 left-0" alt={title} />
            <div className="flex flex-col gap-2 items-start text-white relative">
              {type && <span className="font-bold text-xs">{type}</span>}
              {title && <h2 className="font-bold text-xl md:text-2xl">{title}</h2>}
              {description && <p className="text-sm">{description}</p>}
              {cta_text && (
                <div
                  className={twMerge(
                    'px-4 py-2 lg:px-5 rounded-full border mt-2 cursor-pointer',
                    colorClasses.bg,
                    colorClasses.buttonBorder,
                    colorClasses.bgHover,
                  )}
                >
                  <span className="text-sm lg:text-base font-bold">{cta_text}</span>
                </div>
              )}
            </div>
          </Link>
        </div>
        {items.map((item) => (
          <InventoryItem
            item={item}
            key={item[InventoryField.id]}
            className="w-screen md:w-96 xs:mx-0 px-6 md:pl-10 lg:pl-4 md:-mr-5 lg:mr-5 py-4"
          />
        ))}
      </Slider>
    </div>
  );
};
