import Link from 'next/link';

import { Button, ButtonType } from '@lamesarv-sdk/components';
import { getBodyRoute } from '@lamesarv-sdk/tools';
import { IMetaData } from '@lamesarv-sdk/types';

import { SecondarySection } from './GridFavoritesLayout';

interface GridFavoritesEmptyProps {
  metadata: IMetaData;
}

export const GridFavoritesEmpty = ({ metadata }: GridFavoritesEmptyProps) => (
  <>
    <div className="border border-[#9EA1A3] border-dashed flex flex-col items-center gap-3 md:gap-4 px-8 py-14 md:py-28 rounded-[0.625rem]">
      <h2 className="font-bold text-lg md:text-2xl">Your Adventure Awaits!</h2>
      <p className="text-[#3F585E] text-center text-xs md:text-base">
        You haven't favorited any RVs yet. Explore our top picks and start building your dream RV collection today!
      </p>
      <Link prefetch href="/search">
        <Button type={ButtonType.primary} title="Browse all RVs" className="rounded mt-1" classNameTitle="md:text-lg" />
      </Link>
    </div>
    <SecondarySection title="Browse by RV Types" description="Explore the possibilities and pick your style.">
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-x-5 gap-y-4 md:gap-x-8 md:gap-y-8">
        {metadata.pagesBodyData.map((bodyItem) => (
          <a
            href={getBodyRoute(bodyItem)}
            key={bodyItem.title}
            className="bg-white border border-[#DCDDDE] rounded-[0.625rem] flex flex-col gap-[1.125rem] px-5 py-6 md:px-7 md:py-8 items-center justify-end hover:bg-gray-50"
          >
            <img src={bodyItem.imageUrl} alt={bodyItem.altText} className="w-full max-w-[9.5rem]" />
            <p className="font-medium text-xs md:text-base">{bodyItem.displayText || bodyItem.title}</p>
          </a>
        ))}
      </div>
    </SecondarySection>
  </>
);
