import { useContext } from 'react';

import { InventoryItem } from '@/components/Inventory/InventoryItem';
import { InventoryContext } from '@/contexts/InventoryContext';
import { useIsMobile } from '@/hooks/useIsMobile';
import { getFavoritesChannel } from '@/tools/broadcast';
import { convertPage } from '@/tools/views';
import { IMessageFavorites, MessageFavoritesType } from '@/types/inventory';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonType, Grid } from '@lamesarv-sdk/components';
import { useCustomSearchParams } from '@lamesarv-sdk/hooks';
import { IBasicInventory, IGridBase, IMinimalInventory, InventoryField } from '@lamesarv-sdk/types';

import { CustomPagination } from '../CustomPagination';
import { GridFavoritesContext, GridFavoritesSort } from './GridFavoritesContext';

export interface IMinimalInventoryExtended extends IMinimalInventory {
  _notAvailable?: boolean;
}

const getPriceForSort = (item: IMinimalInventoryExtended) =>
  item[InventoryField.priceSale] || item[InventoryField.priceList];

const sortFunctions: Record<GridFavoritesSort, (a: IMinimalInventoryExtended, b: IMinimalInventoryExtended) => number> =
  {
    [GridFavoritesSort.RECENTLY_ADDED]: () => -1,
    [GridFavoritesSort.PRICE_ASC]: (a, b) => getPriceForSort(a) - getPriceForSort(b),
    [GridFavoritesSort.PRICE_DESC]: (a, b) => getPriceForSort(b) - getPriceForSort(a),
  };

interface GridFavoritesItemsProps {
  gridProps: IGridBase;
}

export const GridFavoritesItems = ({ gridProps }: GridFavoritesItemsProps) => {
  const { favoriteUnselectInventory, context } = useContext(InventoryContext);
  const { searchParams, setSearchParam } = useCustomSearchParams();
  const { filters, sort } = useContext(GridFavoritesContext);

  const page = convertPage(searchParams.get('page'));
  const perPage = useIsMobile() ? 3 : 6;

  const items: IMinimalInventoryExtended[] = (context.inventoryFavorites || [])
    .filter((item) => !filters.body || item[InventoryField.body] === filters.body)
    .sort(sortFunctions[sort])
    .slice((page - 1) * perPage, page * perPage);

  const handleRemoveFavorite = (id: string) => {
    const channelFavorites = getFavoritesChannel();

    const newFavorites = favoriteUnselectInventory(id, true);
    if (!channelFavorites.isClosed) {
      channelFavorites.postMessage({
        type: MessageFavoritesType.favoriteUnselectInventory,
        id,
        quantity: newFavorites.length,
      } as IMessageFavorites);
    }
  };

  return (
    <div className="flex flex-col">
      <Grid
        items={items.map((item) => (
          <div className="flex flex-col h-full" key={item[InventoryField.id]}>
            <div className="w-full flex flex-row justify-end px-2.5" data-id={item[InventoryField.id]}>
              <Button
                type={ButtonType.outline}
                icon={<FontAwesomeIcon icon={faXmark} className="h-4" />}
                title="Remove Item"
                className="flex flex-row items-center gap-1 text-xs text-red-500 border-red-200 border-b-0 py-1 px-3 rounded-none rounded-t-md"
                onClick={() => handleRemoveFavorite(item[InventoryField.id])}
              />
            </div>
            <InventoryItem
              key={item[InventoryField.id]}
              item={item as IBasicInventory}
              customImage={item.image}
              sold={item._notAvailable}
            />
          </div>
        ))}
        baseCols={gridProps.baseCols ?? 1}
        smCols={gridProps.smCols ?? 2}
        mdCols={gridProps.mdCols ?? 2}
        lgCols={gridProps.lgCols ?? 3}
        xlCols={gridProps.xlCols ?? 4}
        gap={gridProps.gap ?? 6}
      />
      <CustomPagination
        totalItems={context.inventoryFavorites?.length || 0}
        currentPage={page}
        pageSize={perPage}
        className="mt-14"
        onNavigate={(page) => {
          setSearchParam('page', page.toString());
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
      />
    </div>
  );
};
