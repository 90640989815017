'use client';

import { PropsWithChildren } from 'react';
import Slider from 'react-slick';
import { twMerge } from 'tailwind-merge';

import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CustomArrowProps {
  onClick?: () => void;
  className?: string;
  isRight?: boolean;
}

const CustomArrow = ({ className, onClick, isRight }: CustomArrowProps) => (
  <button
    type="button"
    onClick={onClick}
    className={twMerge(
      'size-11 bg-white rounded-full shadow-[0px_2px_8px_0px_#00000040] absolute top-1/2 -translate-y-1/2 flex items-center outline-none justify-center cursor-pointer hover:bg-gray-100 z-10',
      isRight ? 'right-4' : 'left-4',
      className,
    )}
  >
    <FontAwesomeIcon className="size-3.5 md:size-5" icon={isRight ? faChevronRight : faChevronLeft} />
  </button>
);

export const RecentArticlesSlider = ({ children }: PropsWithChildren) => (
  <Slider
    dots={false}
    arrows
    infinite={false}
    slidesToScroll={1}
    slidesToShow={1}
    nextArrow={<CustomArrow isRight />}
    prevArrow={<CustomArrow />}
    className="md:hidden"
  >
    {children}
  </Slider>
);
